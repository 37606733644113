function notificate(
  component,
  message,
  title = "Уведомление",
  variant = "primary",
  delay = 5000
) {
  component.$bvToast.toast(`${message}`, {
    title,
    autoHideDelay: delay,
    variant,
  });
}

function notificateError(component, message, code, delay = 5000) {
  component.$bvToast.toast(`${message}`, {
    title: `Код ошибки: ${code}`,
    autoHideDelay: delay,
    variant: "danger",
  });
}


function getPriceLevelNameByCode(code) {
  const levels = {
    OPT1: 'Уровень 1',
    OPT2: 'Уровень 2',
    OPT3: 'Уровень 3',
    OPT4: 'Уровень 4',
    RETAIL_NO_NDS: 'Розница без НДС',
    RETAIL_NDS: 'Розница с НДС',
  }
  if (code in levels) {
    return levels[code]
  }
  return null
}

function humanize(dateStr) { // Конвертирует дату из ISO 8601 в нормальный человекопонятный вариант
  const date = new Date(dateStr)
  console.log('hours', dateStr)
  console.log('len', dateStr.length)
  let hour = ""
  let min = ""
  // Если в строка содержит дату и время
  if (dateStr.length > 10) {
    hour = ("0" + (date.getHours())).slice(-2);
    min = ("0" + (date.getMinutes())).slice(-2);
  }

  if (!isNowDate(dateStr)) {
    const yesterday = new Date()
    yesterday.setDate((new Date()).getDate() - 1)
    if ((yesterday.getFullYear() === date.getFullYear()) && (yesterday.getMonth() === date.getMonth()) && (yesterday.getDate() === date.getDate())) {
      if (hour.length > 0 && min.length) {
        return "Вчера " + hour + ":" + min;
      } else {
        return "Вчера"
      }
    }
    if (hour.length > 0 && min.length) {
      return `${twoDigits(date.getUTCDate())}.${twoDigits(date.getUTCMonth() + 1)}.${date.getUTCFullYear()} ` + hour + ":" + min
    } else {
      return `${twoDigits(date.getUTCDate())}.${twoDigits(date.getUTCMonth() + 1)}.${date.getUTCFullYear()} `
    }
  }
  if (hour.length > 0 && min.length) {
    return "Сегодня " + hour + ":" + min;
  } else {
    return "Сегодня"
  }


}

function isNowDate(dateStr) {
  const nowDate = new Date(Date.now())
  const msgDate = new Date(dateStr)

  const withoutTime = (date) => {
    const str = new Date(date)
    str.setHours(0, 0, 0, 0)
    return str.toString();
  }
  console.log('Check dates', withoutTime(msgDate), withoutTime(nowDate))
  return withoutTime(msgDate) === withoutTime(nowDate);
}

function twoDigits(str) {
  return ('0' + str).slice(-2);
}


export default {
  notificate,
  notificateError,
  getPriceLevelNameByCode,
  humanize,
};
